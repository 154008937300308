.logo-container{
    z-index: 0;
    width: 400px;
    height:609px;
    position: absolute;
    top: 0;
    right: 15%;
    bottom:0;
    left:auto;
    margin:auto;
    transform: rotateZ(30deg);
    



.solid-logo{
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    z-index: 1;
    opacity: 0;
    animation: bounceIn 1s 1s;
    animation-fill-mode: forwards;
}

}
